import React, { useEffect } from 'react';
import { Result, Card } from 'antd';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Certifique-se de que o caminho está correto

const SuccessPage = () => {

  const uid = localStorage.getItem('uid'); // Pega o UID do localStorage
  const paymentCondition = "REALIZADO"; // Condição do pagamento após o sucesso

  useEffect(() => {
    const updatePaymentCondition = async () => {
      try {
        // Atualiza a condição de pagamento para REALIZADO no Firestore
        if (uid) {
          await updateDoc(doc(db, 'clients', uid), { paymentCondition });
          console.log('Condição de pagamento atualizada para REALIZADO');
        }
      } catch (error) {
        console.error('Erro ao atualizar a condição de pagamento:', error);
      }
    };

    updatePaymentCondition();
  }, [uid]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -40, opacity: 0.85 }}>
     
   <Card style={{ width: '90%', opacity: 0.85, overflowY: 'auto', maxHeight: 'calc(70vh - 60px)' }}> <Result
        status="success"
        title="Compra Realizada com Sucesso!"
 extra={[

        ]}
      />
      </Card>
    </div>
  );
};

export default SuccessPage;
